
// Authentication

export const USER_AUTH_BASE_URL: string = `/auth`;
export const LOGIN: string = `${USER_AUTH_BASE_URL}/login`;
export const RESET_PASSWORD: string = `${USER_AUTH_BASE_URL}/reset-password`;
export const RESET_PASSWORD_VERIFY: string = `${USER_AUTH_BASE_URL}/reset-password/verify-reset-password-otp`;
export const SET_PASSWORD: string = `${USER_AUTH_BASE_URL}/set-password/admin/{token}`; 
export const RESET_PASSWORD_CONFIRMATION: string = `${USER_AUTH_BASE_URL}/reset-password/confirmation`;
export const VERIFY_OTP: string = `${USER_AUTH_BASE_URL}/otp/verify`;
export const RESEND_OTP: string = `${USER_AUTH_BASE_URL}/otp/resend`;

