import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserManagement } from '../interfaces/user-management.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private usersList: UserManagement[] = [];

  /**
   * Constructs a new instance of the class.
   *
   * Initializes the `usersList` array with 50 objects representing user information.
   * Each object has the following properties:
   * - id: a number representing the user's ID
   * - first_name: a string representing the user's first name
   * - last_name: a string representing the user's last name
   * - email: a string representing the user's email address
   * - contact: a string representing the user's contact number
   * - role: a string representing the user's role
   * - created_date: a string representing the date the user was created
   * - status: a string representing the user's status (either 'in_active' or 'active')
   *
   * Sets the status of the third user in the `usersList` array to 'active'.
   */
  constructor() {
    for (let index = 0; index < 50; index++) {
      this.usersList.push({
        id: 1,
        first_name: 'Omer',
        last_name: 'Lamin',
        email: 'lamar@domain.com',
        contact: '+220 123 4567',
        role: 'Driver',
        created_date: '21/11/2022',
        status: 'in_active',
      });
    }

    this.usersList[2].status = 'active';
  }

  /**
   * Retrieves the list of users.
   *
   * @return {Observable<UserManagement[]>} An observable that emits an array of UserManagement objects.
   */
  public getUsers(): Observable<UserManagement[]> {
    return of(this.usersList);
  }
}
